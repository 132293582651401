/* eslint-disable no-underscore-dangle */

import Cookies from 'js-cookie';

export enum LocalStorageKeys {
  USER_TOKEN = 'USER_TOKEN',
  LAST_NOTIFICATION_CHECK = 'LAST_NOTIFICATION_CHECK',
  IS_SOUND_ON = 'IS_SOUND_ON',
}

export const doesUserTokenExist = (): boolean => {
  return Cookies.get('chocolateChip') !== undefined;
};

export const getUserToken = () => {
  return Cookies.get('chocolateChip');
};

export const getUserIdFromToken = (
  token = Cookies.get('chocolateChip')
): string => {
  if (token && token !== 'undefined') {
    const payload: any = JSON.parse(atob(token?.split('.')[1]));
    return payload._id;
  }
  return '';
};

export const isCurrentUserSuperUser = (): boolean => {
  const token = Cookies.get('chocolateChip');
  if (token) {
    const payload: any = JSON.parse(atob(token?.split('.')[1]));
    return payload.role.level === 9001;
  }
  return false;
};

export const getJSONParsedItemFromStorage = (key: string): any => {
  const item = localStorage.getItem(key);
  if (item) {
    return JSON.parse(item);
  }
  return null;
};

export const getIsSoundOnFromStorage = (): boolean => {
  const isSoundOn = getJSONParsedItemFromStorage(LocalStorageKeys.IS_SOUND_ON);
  return isSoundOn === null ? true : isSoundOn;
};
