/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { DrawingOption } from '../utils';

interface LocationPageState {
  isDeleteFeatureModalVisible: boolean;
  isDeleteLocationModalVisible: boolean;
  isUploadGeoJSONModalVisible: boolean;
  isSaveAsLocationModalVisible: boolean;
  currentSelectedFeatureId: null | string;
  currentSelectedDrawOption: null | DrawingOption;
}

const initialState: LocationPageState = {
  isDeleteFeatureModalVisible: false,
  isDeleteLocationModalVisible: false,
  isSaveAsLocationModalVisible: false,
  isUploadGeoJSONModalVisible: false,
  currentSelectedFeatureId: null,
  currentSelectedDrawOption: null,
};

export const locationPageSlice = createSlice({
  name: 'locationPage',
  initialState,
  reducers: {
    toggleIsDeleteFeatureModalVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isDeleteFeatureModalVisible = action.payload;
    },
    toggleIsDeleteLocationModalVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isDeleteLocationModalVisible = action.payload;
    },
    toggleIsUploadGeoJSONModalVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isUploadGeoJSONModalVisible = action.payload;
    },
    toggleIsSaveAsLocationModalVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isSaveAsLocationModalVisible = action.payload;
    },
    setCurrentSelectedFeatureId: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.currentSelectedFeatureId = action.payload;
    },
    setCurrentSelectedDrawOption: (
      state,
      action: PayloadAction<DrawingOption | null>
    ) => {
      state.currentSelectedDrawOption = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleIsDeleteFeatureModalVisible,
  toggleIsDeleteLocationModalVisible,
  toggleIsUploadGeoJSONModalVisible,
  toggleIsSaveAsLocationModalVisible,
  setCurrentSelectedFeatureId,
  setCurrentSelectedDrawOption,
} = locationPageSlice.actions;

export default locationPageSlice.reducer;
