/* eslint-disable import/prefer-default-export */
export const DefaultDroneProjections = [
  'name',
  'nickname',
  'state',
  'battery',
  'battery_raw_voltage',
  'heading',
  'user_heading_input',
  'tilt',
  'user_tilt_input',
  'relative_alt_ekf',
  'alt',
  'lat',
  'lon',
  'satellites_visible',
  'distance_from_base',
  'rssi',
  'ipp_status',
  'current_mission_gps_waypoints',
  'active_map_type',
  'm3u8_rgb_url',
  'm3u8_thermal_url',
  'webrtc_rgb_url',
  'webrtc_thermal_url',
  'wowza_application_name',
  'objects',
  'timestamp',
  'snr',
  'mh_status',
  'network_latency',
  'dcs_internal_vpn_latency',
  'locked_to_queue_id',
  'launch_id',
  'rgb_crop',
  'thermal_crop',
  'rssi',
  'snr',
  'mh_status',
  'sm_state',
  'vfr_groundspeed',
  'rid_status',
  'rid_timestamp',
  'radar_left_curr_distance_m',
  'radar_center_curr_distance_m',
  'radar_right_curr_distance_m',
];

export const DroneLaunchProjections = [
  'grounded',
  'launch_id',
  'pre_flight_state',
  'IPP_success',
  'altitude_initialized',
  'got_base_status',
  'roof_open',
  'system_check_success',
  'rc_channels_raw',
  'servo_output_raw',
  'sm_state',
  '_id',
  'rid_status',
];

export const DroneActivationCheckProjections = ['state', '_id'];

export const DroneGroundCheckProjections = ['grounded', '_id'];

export const SignalStrengthProjections = ['rssi', 'snr', 'mh_status'];

export const zoomProjections = ['rgb_crop', 'thermal_crop'];
