/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface PreLaunchModalsState {
  isSelectDroneModalVisible: boolean;
  isLaunchRequestModalVisible: boolean;
  isLaunchCheckModalVisible: boolean;
  isAcceptDFRmissionPopup: boolean;
  locationId: string;
  drone: any;
  mission: any;
  dfrMission: any;
  stream: string;
  launchData: any;
}

const initialState: PreLaunchModalsState = {
  isSelectDroneModalVisible: false,
  isLaunchRequestModalVisible: false,
  isLaunchCheckModalVisible: false,
  isAcceptDFRmissionPopup: false,
  locationId: '',
  drone: null,
  mission: null,
  dfrMission: null,
  stream: '',
  launchData: null,
};

export const preLaunchModalsSlice = createSlice({
  name: 'prelaunchModals',
  initialState,
  reducers: {
    toggleIsSelectDroneModalVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isSelectDroneModalVisible = action.payload;
    },
    toggleIsLaunchRequestModalVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isLaunchRequestModalVisible = action.payload;
    },
    toggleIsLaunchCheckModalVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isLaunchCheckModalVisible = action.payload;
    },
    toggleIsAcceptDFRmissionPopup: (state, action: PayloadAction<boolean>) => {
      state.isAcceptDFRmissionPopup = action.payload;
    },
    setDFRLocationId: (state, action: PayloadAction<string>) => {
      state.locationId = action.payload;
    },
    setPrelaunchMission: (state, action: PayloadAction<any>) => {
      state.mission = { ...action.payload };
    },
    setPrelaunchDrone: (state, action: PayloadAction<any>) => {
      state.drone = { ...action.payload };
    },
    setPrelaunchStream: (state, action: PayloadAction<string>) => {
      state.stream = action.payload;
    },
    setLaunchData: (state, action: PayloadAction<any>) => {
      state.launchData = { ...action.payload };
    },
    setDfrMission: (state, action: PayloadAction<any>) => {
      state.dfrMission = { ...action.payload };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleIsSelectDroneModalVisible,
  toggleIsLaunchRequestModalVisible,
  toggleIsLaunchCheckModalVisible,
  toggleIsAcceptDFRmissionPopup,
  setPrelaunchMission,
  setPrelaunchDrone,
  setPrelaunchStream,
  setLaunchData,
  setDFRLocationId,
  setDfrMission,
} = preLaunchModalsSlice.actions;

export default preLaunchModalsSlice.reducer;
